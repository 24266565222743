import {ValidatorFn, ValidationErrors, AbstractControl, Validators, AsyncValidatorFn, FormGroup, FormControl} from '@angular/forms';
import {Observable, of} from 'rxjs';
import {MerchantWebsite, AffiliateRelationshipMerchant} from '../dtos/api';
import {catchError, first, map} from 'rxjs/operators';
import {addHours, isBefore, addMinutes} from 'date-fns';

interface FormControlInformation {
  name: string;
  setErrorOnForm: boolean;
}
export interface IsEndAfterStartDateParams {
  customMessage: string;
  includeHoursAndMinutes: boolean;
  startDateFormControl: FormControlInformation;
  startTimeFormControl: FormControlInformation;
  endDateFormControl: FormControlInformation;
  endTimeFormControl: FormControlInformation;
}

// eslint-disable-next-line max-len
const emailRegex = /^(?=.{1,254}$)(?=.{1,64}@)[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

let errorMessages = {
  // Angular out of the box validators
  email: () => 'Please enter a valid email address.',
  emailArray: () => 'An email address in invalid.',
  matDatepickerParse: () => 'Please enter a valid date.',
  min: (props: {min: number; actual: number}) => `Please enter a value greater than or equal to ${props.min}.`,
  minlength: (props: {requiredLength: number; actualLength: number}) => `Please enter at least ${props.requiredLength} characters.`,
  max: (props: {max: number; actual: number}) => `Please enter a value less than or equal to ${props.max}.`,
  maxlength: (props: {requiredLength: number; actualLength: number}) => `Please enter no more than ${props.requiredLength} characters.`,
  maxlengthArray: (props: {requiredLength: number; actualLength: number}) => `Please enter no more than ${props.requiredLength} characters.`,
  exactlength: (props: {requiredLength: number; actualLength: number}) => `Please enter a value ${props.requiredLength} characters long.`,
  pattern: (props: {requiredPattern: string; actualValue: string}) => `Please enter a value that matches regex pattern ${props.requiredPattern}.`,
  required: () => 'This field is required.',
  digits: () => 'Please enter only digits.',
  commaDelimitedDigits: () => 'Please enter only comma-separated digits.',
  futureDateTimeRequired: () => 'Future date/time required.',
  changeDateBeforeEndDate: () => 'Must be before end date.',
  endDateBeforeScheduleDate: () => 'Must be after schedule date.',

  // Custom Validators
  // typeObject most useful when used with a customMessage
  typeObject: () => 'An object is required.',
  url: () => 'Please enter a valid URL.',
  merchantUrl: () => 'Please enter a valid merchant URL.',
  urlProtocol: () => 'Protocol required (https:// or http://).'
};

// This regex was taken from here: https://gist.github.com/dperini/729294
const urlRegularExpression = new RegExp( '^' +
  // protocol identifier (optional)
  // short syntax // still required
  '(?:(?:(?:https?):)?\\/\\/)' +
  // user:pass BasicAuth (optional)
  '(?:\\S+(?::\\S*)?@)?' +
  '(?:' +
  // IP address exclusion
  // private & local networks
  '(?!(?:10|127)(?:\\.\\d{1,3}){3})' +
  '(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})' +
  '(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})' +
  // IP address dotted notation octets
  // excludes loopback network 0.0.0.0
  // excludes reserved space >= 224.0.0.0
  // excludes network & broadcast addresses
  // (first & last IP address of each class)
  '(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])' +
  '(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}' +
  '(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))' +
  '|' +
  // host & domain names, may end with dot
  // can be replaced by a shortest alternative
  // (?![-_])(?:[-\\w\\u00a1-\\uffff]{0,63}[^-_]\\.)+
  '(?:' +
  '(?:' +
  '[a-z0-9\\u00a1-\\uffff]' +
  '[a-z0-9\\u00a1-\\uffff_-]{0,62}' +
  ')?' +
  '[a-z0-9\\u00a1-\\uffff]\\.' +
  ')+' +
  // TLD identifier name, may end with dot
  '(?:[a-z\\u00a1-\\uffff]{2,}\\.?)' +
  ')' +
  // port number (optional)
  '(?::\\d{2,5})?' +
  // resource path (optional)
  '(?:[/?#]\\S*)?' +
  '$', 'i');

export function validationMessage(errors: ValidationErrors): string {
  // 'errors' should never be an empty object ({}).
  // if control.setError is passed an empty object, elseware angular will set the control as errored with red, but not have an error message.
  if (errors == null) {
    return '';
  }

  let errorEntries = Object.entries(errors);
  // There is a bug in the datepicker where if matDatepickerParse error exists, required is still shown even if there is data in the field.
  // Show required if it is the only error.
  let [errorKey, errorValue] = errorEntries.find(([key,]) => errorEntries.length === 1 || key !== 'required');

  if (errorValue.customMessage != null) {
    return errorValue.customMessage;
  }

  if (errorMessages[errorKey] == null) {
    return 'This field is not valid.';
  }

  return errorMessages[errorKey](errorValue);
}

export function typeObjectValidator(customMessage?: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    let value = control.value;

    return (value && typeof value === 'object') ? null : { typeObject: { customMessage } };
  };
}

export function urlValidator(customMessage?: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    let value = control.value;

    return (value && urlRegularExpression.test(value)) ? null : { url: { customMessage } };
  };
}

export function merchantUrlValidator(observableForUrls: Observable<MerchantWebsite[]>, customMessage?: string): AsyncValidatorFn {
  return (control: AbstractControl): Observable<ValidationErrors | null> => {
    let value = control.value;

    return observableForUrls.pipe(
      first(),
      map(urls => {
        if (!value.match(/^https?:\/\//)) {
          return { urlProtocol: {customMessage}};
        }
        let targetHostName = new URL(value).hostname.replace(/^(www\.)/, '');
        return urls.filter(website => website.is_active).some(website => {
          let websiteHostName = new URL(website.merchant_website_url).hostname.replace(/^(www\.)/, '');
          return targetHostName === websiteHostName;
        }) ? null : { merchantUrl: { customMessage }};
      }),
      catchError((error) => of({merchantUrl: { customMessage }}))
    );
  };
}

// wrap built in angular pattern validator to allow customMessage
export function patternValidator(regex: string|RegExp, customMessage?: string): ValidatorFn {
  let angularPattern = Validators.pattern(regex);

  return (control: AbstractControl): ValidationErrors | null => {
    let validationResult = angularPattern(control);

    if (validationResult == null) {
      return null;
    }

    return customMessage == null ? validationResult : { pattern: { customMessage } };
  };
}

export function exactLengthValidator(exactLength: number, customMessage?: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors => {
    if (isEmptyInputValue(control.value) || !hasValidLength(control.value)) {
      return null;
    }

    if (control.value.length !== exactLength) {
      return customMessage == null ? {exactlength: {requiredLength: exactLength, actualLength: control.value.length}} : { exactlength: { customMessage } };
    }

    return null;
  };
}

export function digitsValidator(customMessage?: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors => {
    if (isEmptyInputValue(control.value)) {
      return null;
    }

    if (!/^\d+$/.test(control.value)) {
      return customMessage == null ? { digits: true } : { digits: { customMessage } };
    }

    return null;
  };
}

export function emailArrayValidator(customMessage?: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors => {
    if (isEmptyInputValue(control.value) || !Array.isArray(control.value)) {
      return null;
    }

    if ((<string[]> control.value).some(x => !emailRegex.test(x))) {
      return customMessage == null ? { emailArray: true } : { emailArray: { customMessage } };
    }

    return null;
  };
}

export function maxLengthArrayValidator(maxLength: number, separatorLength: number, customMessage?: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors => {
    if (isEmptyInputValue(control.value) || !Array.isArray(control.value)) {
      return null;
    }

    let totalCharacters = (<string[]> control.value).reduce((acc, current) => acc + current.length, 0);
    let lengthOfArray = (<string[]> control.value).length;
    if (lengthOfArray > 1 && separatorLength > 0) {
      totalCharacters += (lengthOfArray * separatorLength) - 1;
    }

    if (totalCharacters > maxLength) {
      return customMessage == null ? { maxlengthArray: {requiredLength: maxLength, actualLength: totalCharacters} } : { maxlengthArray: { customMessage } };
    }

    return null;
  };
}

function isEmptyInputValue(value: any): boolean {
  // don't validate empty values to allow optional controls
  // we don't check for string here so it also works with arrays
  return value == null || value.length === 0;
}

function hasValidLength(value: any): boolean {
  // don't validate values without `length` property
  // non-strict comparison is intentional, to check for both `null` and `undefined` values
  return value != null && typeof value.length === 'number';
}

export function affiliateMerchantUrlValidator(observableForUrls: Observable<AffiliateRelationshipMerchant[]>, customMessage?: string): AsyncValidatorFn {
  return (control: AbstractControl): Observable<ValidationErrors | null> => {
    let value = control.value;

    return observableForUrls.pipe(
      first(),
      map(urls => {
        if (!value.match(/^https?:\/\//)) {
          return { urlProtocol: {customMessage}};
        }
        let targetHostName = new URL(value).hostname.replace(/^(www\.)/, '');
        return urls.some(website => {
          let websiteHostName = new URL(website.merchant_website_url)?.hostname.replace(/^(www\.)/, '');
          return targetHostName === websiteHostName;
        }) ? null : { merchantUrl: { customMessage }};
      }),
      catchError((error) => of({merchantUrl: { customMessage }}))
    );
  };
}

export function isEndAfterStartDateValidator(isEndAfterStartDateParams: IsEndAfterStartDateParams): ValidatorFn {
  return (formGroup: FormGroup): ValidationErrors | null => {
    let startDate = formGroup.get(isEndAfterStartDateParams.startDateFormControl.name).value;
    let startTime = formGroup.get(isEndAfterStartDateParams.startTimeFormControl.name).value;

    let endDate = formGroup.get(isEndAfterStartDateParams.endDateFormControl.name).value;
    let endTime = formGroup.get(isEndAfterStartDateParams.endTimeFormControl.name).value;

    if (startDate === null || startTime < 0 || endDate === null || (endTime === -1 || endTime === null)) {
      return null;
    }

    let startCompleteDate: Date;
    let endCompleteDate: Date;
    if (isEndAfterStartDateParams.includeHoursAndMinutes) {
      let [startTimeHours, startTimeMinutes] = startTime.split(':').map(x => parseInt(x, 10));
      let [endTimeHours, endTimeMinutes] = endTime.split(':').map(x => parseInt(x, 10));
      startCompleteDate = addMinutes(addHours(startDate, startTimeHours), startTimeMinutes);
      endCompleteDate = addMinutes(addHours(endDate, endTimeHours), endTimeMinutes);
    } else {
      startCompleteDate = addHours(startDate, startTime);
      endCompleteDate = addHours(endDate, endTime);
    }

    const controlKeys = ['startDateFormControl', 'endDateFormControl', 'endTimeFormControl', 'startDateFormControl'];
    const controlsToSetErrorOn = Object.keys(isEndAfterStartDateParams).filter(keyName => controlKeys.includes(keyName)
      && isEndAfterStartDateParams[keyName].setErrorOnForm).map(keyName => isEndAfterStartDateParams[keyName].name);
    controlsToSetErrorOn.forEach(controlName => {
      if (isBefore(startCompleteDate, endCompleteDate)) {
        formGroup.controls[controlName].setErrors(null);
      } else {
        formGroup.controls[controlName].setErrors({isEndAfterStartDateValidator: {customMessage: isEndAfterStartDateParams.customMessage}});
      }
    });
    return isBefore(startCompleteDate, endCompleteDate) ? null : {isEndAfterStartDateValidator: {customMessage: isEndAfterStartDateParams.customMessage}};
  };
}
