import { NgModule } from '@angular/core';

import { AuthyApiService } from './authy-api.service';

@NgModule({
  providers: [
    AuthyApiService
  ]
})
export class AuthyApiModule { }
export { AuthyApiService } from './authy-api.service';
