<div class="form-container">
  <form class="register-device-form" (ngSubmit)="sendCode()" #registerDeviceForm="ngForm">
    <app-logo-link></app-logo-link>
    <p class="message">
      Logging in from a new device or browser?
    </p>
    <p>
      Send a verification code to {{loginStateService.phoneNumber}}. If you do not have access to this number <a href="http://support.avantlink.com/hc/en-us/requests/new" target="_blank">contact support</a>.
    </p>
    @if(doesCountryCodeAllowVoice()) {
      <div class="container">
        <mat-radio-group name="sendMethod" [(ngModel)]="loginStateService.sendMethod">
          <mat-radio-button value="sms">
            Text me
          </mat-radio-button>
          <mat-radio-button value="call">
            Call me
          </mat-radio-button>
        </mat-radio-group>
      </div>
    }
    <button #submitButton color="primary" type="submit" mat-raised-button [disabled]="!registerDeviceForm.valid">
      Send Code
    </button>
  </form>
  <div>
    <p>
      <a [routerLink]="['/login']">Back to login</a>
    </p>
  </div>
</div>
