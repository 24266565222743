import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DashboardHttpService } from '../../../core-services/dashboard-http-service/dashboard-http.service';
import { environment } from '../../../../environments/environment';
import {
  LoginRequest, Login, ResetPasswordRequest, ResetPassword, ForgotPasswordRequest, ForgotPassword, Logout, CurrentUserAccount, NetworkSearch,
  EntityGroupName, SearchedAccount, Token
} from '../../dtos/api';
import { HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import {ApiService} from '../../../api/api.service';
import {GetOptions} from '../../../core-services/models';

@Injectable({
  providedIn: 'root'
})
export class LoginApiService {
  private path = environment.apiUrl + '/login';

  constructor(private http: DashboardHttpService, private apiService: ApiService) { }

  public login(login: LoginRequest): Observable<Login> {
    return this.http.post(this.path, login, false);
  }

  public resetPassword(resetPassword: ResetPasswordRequest): Observable<ResetPassword> {
    let options: GetOptions = { headers: new HttpHeaders({authorization: this.apiService.dashboardAuthorization}), saveAuthorizationCookie: false };
    return this.http.postWithOptions(this.path + '/reset-password', resetPassword, options);
  }

  public sendResetCode(request: ForgotPasswordRequest): Observable<ForgotPassword> {
    let options: GetOptions = { headers: new HttpHeaders({authorization: this.apiService.dashboardAuthorization}), saveAuthorizationCookie: false };
    return this.http.postWithOptions(this.path + '/forgot-password', request, options);
  }

  public logout(): Observable<Logout> {
    return this.http.post(this.path + '/logout', {}, false);
  }

  /**
   * Probably should use currentAccount$ observable in dashboardapi.service instead of this call.
   */
  public currentAccount(): Observable<CurrentUserAccount> {
    return this.http.getWithOptions<CurrentUserAccount>(this.path + '/current-account', { headers: new HttpHeaders({'Cache-Control': 'no-cache'}) })
      .pipe(
        // api actually returns a string for classic_entity_id. Change to number to match other classic Ids and to be compatible with existing typings.
        map(x => ({...x, classic_entity_id: parseInt(<string> <any> x.classic_entity_id, 10) || null}))
      );
  }

  public searchAccounts(searchTerm: string, filteredNetwork: NetworkSearch, filteredEntityGroup: EntityGroupName = 'any'): Observable<SearchedAccount[]> {
    let encodedSearchTerm = encodeURIComponent(searchTerm);
    return this.http.get<SearchedAccount[]>(`${this.path}/search/${encodedSearchTerm}` +
      `/${filteredNetwork}` + (filteredEntityGroup ? `/${filteredEntityGroup}` : ''));
  }

  public getTimeLeftInSession(): Observable<Token> {
    // Another tab could have potentially refreshed auth token so refresh headers to get most current auth cookie.
    this.apiService.refreshHeaders();
    return this.http.getWithOptions<Token>(
      environment.apiUrl + '/token', {saveAuthorizationCookie: false, checkAuthenticationMaybeRedirect: false});
  }
}
