import { Injectable } from '@angular/core';

import {SendMethod} from '../../core/dtos/api';
import {CountryCode, countryCodes} from '../country-code';

@Injectable()
export class LoginStateService {
  public emailAddress: string;
  public sendMethod: SendMethod;
  public phoneNumber: string;
  public countryCode: CountryCode;
  public initialPasswordSetup: boolean;

  constructor() {
    this.sendMethod = 'sms';
    this.countryCode = countryCodes[0];
    this.initialPasswordSetup = false;
  }
}
