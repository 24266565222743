import {Component, OnInit, signal} from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { AuthyApiService } from '../../api-services/authy-api/authy-api.module';
import { LoginStateService } from '../login-state-service/login-state.service';
import { HttpErrorResponse } from '@angular/common/http';
import { SnackBarService } from '../../core-services/snack-bar/snack-bar.service';
import {countryCodeDoesNotAllowVoice, countryCodes} from '../country-code';
import {MatSelectChange} from '@angular/material/select';

@Component({
  selector: 'app-setup-phone',
  templateUrl: './setup-phone.component.html',
  styleUrls: ['./setup-phone.component.scss']
})
export class SetupPhoneComponent implements OnInit {
  readonly countryCodes = countryCodes;
  private setupPhoneSubscription: Subscription;
  doesCountryCodeAllowVoice = signal(true);

  constructor(
    private authyService: AuthyApiService,
    private router: Router,
    public loginStateService: LoginStateService,
    private snackBar: SnackBarService,
  ) { }

  public ngOnInit() {
    this.loginStateService.countryCode = countryCodes[0];
    this.doesCountryCodeAllowVoice.set(!countryCodeDoesNotAllowVoice.includes(this.loginStateService.countryCode.value));
  }

  public setupPhone() {
    if (!this.setupPhoneSubscription) {
      this.setupPhoneSubscription = this.authyService.setup({
        phone_number: this.loginStateService.phoneNumber,
        country_code: this.loginStateService.countryCode.value,
        send_method: this.doesCountryCodeAllowVoice() ? this.loginStateService.sendMethod : 'sms'
      }).subscribe(
        {
          next: () => {
            let phoneNumber = this.loginStateService.phoneNumber;
            this.snackBar.openSnackBar('A verification code was sent to: ' + phoneNumber);
            this.router.navigate(['/login/verify-phone'], {queryParamsHandling: 'preserve'});
          },
          error: (errorResponse: HttpErrorResponse) => this.snackBar.openErrorSnackBar(errorResponse.error.error)
        }
      );
      this.setupPhoneSubscription?.add(() => this.setupPhoneSubscription = null);
    }
  }

  onCountryCodeChange(countryCode: MatSelectChange) {
    this.doesCountryCodeAllowVoice.set(!countryCodeDoesNotAllowVoice.includes(countryCode.value.value));
    if (!this.doesCountryCodeAllowVoice()) {
      this.loginStateService.sendMethod = 'sms';
    }
  }
}
