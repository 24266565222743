export interface CountryCode {
  selected_label: string;
  option_label: string;
  value: number;
}

export const countryCodes: CountryCode[] = [
    {
      selected_label: '(+1)',
      option_label: 'United States (+1)',
      value: 1
    },
    {
      selected_label: '(+1)',
      option_label: 'Canada (+1)',
      value: 1
    },
    {
      selected_label: '(+61)',
      option_label: 'Australia (+61)',
      value: 61
    },
    {
      selected_label: '(+93)',
      option_label: 'Afghanistan (+93)',
      value: 93
    },
    {
      selected_label: '(+355)',
      option_label: 'Albania (+355)',
      value: 355
    },
    {
      selected_label: '(+213)',
      option_label: 'Algeria (+213)',
      value: 213
    },
    {
      selected_label: '(+376)',
      option_label: 'Andorra (+376)',
      value: 376
    },
    {
      selected_label: '(+244)',
      option_label: 'Angola (+244)',
      value: 244
    },
    {
      selected_label: '(+1264)',
      option_label: 'Anguilla (+1264)',
      value: 1264
    },
    {
      selected_label: '(+1268)',
      option_label: 'Antigua and Barbuda (+1268)',
      value: 1268
    },
    {
      selected_label: '(+54)',
      option_label: 'Argentina (+54)',
      value: 54
    },
    {
      selected_label: '(+374)',
      option_label: 'Armenia (+374)',
      value: 374
    },
    {
      selected_label: '(+297)',
      option_label: 'Aruba (+297)',
      value: 297
    },
    {
      selected_label: '(+43)',
      option_label: 'Austria (+43)',
      value: 43
    },
    {
      selected_label: '(+994)',
      option_label: 'Azerbaijan (+994)',
      value: 994
    },
    {
      selected_label: '(+1242)',
      option_label: 'Bahamas (+1242)',
      value: 1242
    },
    {
      selected_label: '(+973)',
      option_label: 'Bahrain (+973)',
      value: 973
    },
    {
      selected_label: '(+880)',
      option_label: 'Bangladesh (+880)',
      value: 880
    },
    {
      selected_label: '(+1246)',
      option_label: 'Barbados (+1246)',
      value: 1246
    },
    {
      selected_label: '(+375)',
      option_label: 'Belarus (+375)',
      value: 375
    },
    {
      selected_label: '(+32)',
      option_label: 'Belgium (+32)',
      value: 32
    },
    {
      selected_label: '(+501)',
      option_label: 'Belize (+501)',
      value: 501
    },
    {
      selected_label: '(+229)',
      option_label: 'Benin (+229)',
      value: 229
    },
    {
      selected_label: '(+1441)',
      option_label: 'Bermuda (+1441)',
      value: 1441
    },
    {
      selected_label: '(+975)',
      option_label: 'Bhutan (+975)',
      value: 975
    },
    {
      selected_label: '(+591)',
      option_label: 'Bolivia (+591)',
      value: 591
    },
    {
      selected_label: '(+387)',
      option_label: 'Bosnia-Herzegovina (+387)',
      value: 387
    },
    {
      selected_label: '(+267)',
      option_label: 'Botswana (+267)',
      value: 267
    },
    {
      selected_label: '(+55)',
      option_label: 'Brazil (+55)',
      value: 55
    },
    {
      selected_label: '(+673)',
      option_label: 'Brunei (+673)',
      value: 673
    },
    {
      selected_label: '(+359)',
      option_label: 'Bulgaria (+359)',
      value: 359
    },
    {
      selected_label: '(+226)',
      option_label: 'Burkina Faso (+226)',
      value: 226
    },
    {
      selected_label: '(+257)',
      option_label: 'Burundi (+257)',
      value: 257
    },
    {
      selected_label: '(+855)',
      option_label: 'Cambodia (+855)',
      value: 855
    },
    {
      selected_label: '(+237)',
      option_label: 'Cameroon (+237)',
      value: 237
    },
    {
      selected_label: '(+238)',
      option_label: 'Cape Verde Islands (+238)',
      value: 238
    },
    {
      selected_label: '(+1345)',
      option_label: 'Cayman Islands (+1345)',
      value: 1345
    },
    {
      selected_label: '(+236)',
      option_label: 'Central African Republic (+236)',
      value: 236
    },
    {
      selected_label: '(+235)',
      option_label: 'Chad (+235)',
      value: 235
    },
    {
      selected_label: '(+56)',
      option_label: 'Chile (+56)',
      value: 56
    },
    {
      selected_label: '(+86)',
      option_label: 'China (+86)',
      value: 86
    },
    {
      selected_label: '(+57)',
      option_label: 'Colombia (+57)',
      value: 57
    },
    {
      selected_label: '(+242)',
      option_label: 'Congo (+242)',
      value: 242
    },
    {
      selected_label: '(+243)',
      option_label: 'Congo, Democratic Republ (+243)',
      value: 243
    },
    {
      selected_label: '(+682)',
      option_label: 'Cook Islands (+682)',
      value: 682
    },
    {
      selected_label: '(+506)',
      option_label: 'Costa Rica (+506)',
      value: 506
    },
    {
      selected_label: '(+385)',
      option_label: 'Croatia (+385)',
      value: 385
    },
    {
      selected_label: '(+53)',
      option_label: 'Cuba (+53)',
      value: 53
    },
    {
      selected_label: '(+357)',
      option_label: 'Cyprus (+357)',
      value: 357
    },
    {
      selected_label: '(+420)',
      option_label: 'Czech Republic (+420)',
      value: 420
    },
    {
      selected_label: '(+45)',
      option_label: 'Denmark (+45)',
      value: 45
    },
    {
      selected_label: '(+253)',
      option_label: 'Djibouti (+253)',
      value: 253
    },
    {
      selected_label: '(+1767)',
      option_label: 'Dominica (+1767)',
      value: 1767
    },
    {
      selected_label: '(+1809)',
      option_label: 'Dominican Republic (+1809)',
      value: 1809
    },
    {
      selected_label: '(+1829)',
      option_label: 'Dominican Republic2 (+1829)',
      value: 1829
    },
    {
      selected_label: '(+1849)',
      option_label: 'Dominican Republic3 (+1849)',
      value: 1849
    },
    {
      selected_label: '(+593)',
      option_label: 'Ecuador (+593)',
      value: 593
    },
    {
      selected_label: '(+20)',
      option_label: 'Egypt (+20)',
      value: 20
    },
    {
      selected_label: '(+503)',
      option_label: 'El Salvador (+503)',
      value: 503
    },
    {
      selected_label: '(+372)',
      option_label: 'Estonia (+372)',
      value: 372
    },
    {
      selected_label: '(+251)',
      option_label: 'Ethiopia (+251)',
      value: 251
    },
    {
      selected_label: '(+500)',
      option_label: 'Falkland Islands (+500)',
      value: 500
    },
    {
      selected_label: '(+298)',
      option_label: 'Faroe Islands (+298)',
      value: 298
    },
    {
      selected_label: '(+679)',
      option_label: 'Fiji Islands (+679)',
      value: 679
    },
    {
      selected_label: '(+358)',
      option_label: 'Finland (+358)',
      value: 358
    },
    {
      selected_label: '(+33)',
      option_label: 'France (+33)',
      value: 33
    },
    {
      selected_label: '(+594)',
      option_label: 'French Guiana (+594)',
      value: 594
    },
    {
      selected_label: '(+689)',
      option_label: 'French Polynesia (+689)',
      value: 689
    },
    {
      selected_label: '(+241)',
      option_label: 'Gabon (+241)',
      value: 241
    },
    {
      selected_label: '(+220)',
      option_label: 'Gambia (+220)',
      value: 220
    },
    {
      selected_label: '(+995)',
      option_label: 'Georgia (+995)',
      value: 995
    },
    {
      selected_label: '(+49)',
      option_label: 'Germany (+49)',
      value: 49
    },
    {
      selected_label: '(+233)',
      option_label: 'Ghana (+233)',
      value: 233
    },
    {
      selected_label: '(+350)',
      option_label: 'Gibraltar (+350)',
      value: 350
    },
    {
      selected_label: '(+30)',
      option_label: 'Greece (+30)',
      value: 30
    },
    {
      selected_label: '(+299)',
      option_label: 'Greenland (+299)',
      value: 299
    },
    {
      selected_label: '(+1473)',
      option_label: 'Grenada (+1473)',
      value: 1473
    },
    {
      selected_label: '(+590)',
      option_label: 'Guadeloupe (+590)',
      value: 590
    },
    {
      selected_label: '(+1671)',
      option_label: 'Guam (+1671)',
      value: 1671
    },
    {
      selected_label: '(+502)',
      option_label: 'Guatemala (+502)',
      value: 502
    },
    {
      selected_label: '(+44)',
      option_label: 'Guernsey (+44)',
      value: 44
    },
    {
      selected_label: '(+224)',
      option_label: 'Guinea (+224)',
      value: 224
    },
    {
      selected_label: '(+245)',
      option_label: 'Guinea-Bissau (+245)',
      value: 245
    },
    {
      selected_label: '(+592)',
      option_label: 'Guyana (+592)',
      value: 592
    },
    {
      selected_label: '(+509)',
      option_label: 'Haiti (+509)',
      value: 509
    },
    {
      selected_label: '(+504)',
      option_label: 'Honduras (+504)',
      value: 504
    },
    {
      selected_label: '(+852)',
      option_label: 'HongKong (+852)',
      value: 852
    },
    {
      selected_label: '(+36)',
      option_label: 'Hungary (+36)',
      value: 36
    },
    {
      selected_label: '(+354)',
      option_label: 'Iceland (+354)',
      value: 354
    },
    {
      selected_label: '(+91)',
      option_label: 'India (+91)',
      value: 91
    },
    {
      selected_label: '(+62)',
      option_label: 'Indonesia (+62)',
      value: 62
    },
    {
      selected_label: '(+882)',
      option_label: 'International (+882)',
      value: 882
    },
    {
      selected_label: '(+98)',
      option_label: 'Iran (+98)',
      value: 98
    },
    {
      selected_label: '(+964)',
      option_label: 'Iraq (+964)',
      value: 964
    },
    {
      selected_label: '(+353)',
      option_label: 'Ireland (+353)',
      value: 353
    },
    {
      selected_label: '(+44)',
      option_label: 'Isle of Man (+44)',
      value: 44
    },
    {
      selected_label: '(+972)',
      option_label: 'Israel (+972)',
      value: 972
    },
    {
      selected_label: '(+39)',
      option_label: 'Italy (+39)',
      value: 39
    },
    {
      selected_label: '(+225)',
      option_label: 'Ivory Coast (+225)',
      value: 225
    },
    {
      selected_label: '(+1876)',
      option_label: 'Jamaica (+1876)',
      value: 1876
    },
    {
      selected_label: '(+81)',
      option_label: 'Japan (+81)',
      value: 81
    },
    {
      selected_label: '(+44)',
      option_label: 'Jersey (+44)',
      value: 44
    },
    {
      selected_label: '(+962)',
      option_label: 'Jordan (+962)',
      value: 962
    },
    {
      selected_label: '(+7)',
      option_label: 'Kazakhstan (+7)',
      value: 7
    },
    {
      selected_label: '(+254)',
      option_label: 'Kenya (+254)',
      value: 254
    },
    {
      selected_label: '(+North)',
      option_label: 'Korea (+North) (+850)',
      value: 850
    },
    {
      selected_label: '(+South)',
      option_label: 'Korea (+South) (+82)',
      value: 82
    },
    {
      selected_label: '(+965)',
      option_label: 'Kuwait (+965)',
      value: 965
    },
    {
      selected_label: '(+996)',
      option_label: 'Kyrgyzstan (+996)',
      value: 996
    },
    {
      selected_label: '(+856)',
      option_label: 'Laos (+856)',
      value: 856
    },
    {
      selected_label: '(+371)',
      option_label: 'Latvia (+371)',
      value: 371
    },
    {
      selected_label: '(+961)',
      option_label: 'Lebanon (+961)',
      value: 961
    },
    {
      selected_label: '(+266)',
      option_label: 'Lesotho (+266)',
      value: 266
    },
    {
      selected_label: '(+231)',
      option_label: 'Liberia (+231)',
      value: 231
    },
    {
      selected_label: '(+218)',
      option_label: 'Libya (+218)',
      value: 218
    },
    {
      selected_label: '(+423)',
      option_label: 'Liechtenstein (+423)',
      value: 423
    },
    {
      selected_label: '(+370)',
      option_label: 'Lithuania (+370)',
      value: 370
    },
    {
      selected_label: '(+352)',
      option_label: 'Luxembourg (+352)',
      value: 352
    },
    {
      selected_label: '(+853)',
      option_label: 'Macau (+853)',
      value: 853
    },
    {
      selected_label: '(+389)',
      option_label: 'Macedonia (+389)',
      value: 389
    },
    {
      selected_label: '(+261)',
      option_label: 'Madagascar (+261)',
      value: 261
    },
    {
      selected_label: '(+265)',
      option_label: 'Malawi (+265)',
      value: 265
    },
    {
      selected_label: '(+60)',
      option_label: 'Malaysia (+60)',
      value: 60
    },
    {
      selected_label: '(+960)',
      option_label: 'Maldives (+960)',
      value: 960
    },
    {
      selected_label: '(+223)',
      option_label: 'Mali Republic (+223)',
      value: 223
    },
    {
      selected_label: '(+356)',
      option_label: 'Malta (+356)',
      value: 356
    },
    {
      selected_label: '(+596)',
      option_label: 'Martinique (+596)',
      value: 596
    },
    {
      selected_label: '(+222)',
      option_label: 'Mauritania (+222)',
      value: 222
    },
    {
      selected_label: '(+230)',
      option_label: 'Mauritius (+230)',
      value: 230
    },
    {
      selected_label: '(+269)',
      option_label: 'Mayotte Island (+269)',
      value: 269
    },
    {
      selected_label: '(+52)',
      option_label: 'Mexico (+52)',
      value: 52
    },
    {
      selected_label: '(+373)',
      option_label: 'Moldova (+373)',
      value: 373
    },
    {
      selected_label: '(+377)',
      option_label: 'Monaco (+377)',
      value: 377
    },
    {
      selected_label: '(+976)',
      option_label: 'Mongolia (+976)',
      value: 976
    },
    {
      selected_label: '(+382)',
      option_label: 'Montenegro (+382)',
      value: 382
    },
    {
      selected_label: '(+1664)',
      option_label: 'Montserrat (+1664)',
      value: 1664
    },
    {
      selected_label: '(+212)',
      option_label: 'Morocco (+212)',
      value: 212
    },
    {
      selected_label: '(+258)',
      option_label: 'Mozambique (+258)',
      value: 258
    },
    {
      selected_label: '(+95)',
      option_label: 'Myanmar (+95)',
      value: 95
    },
    {
      selected_label: '(+264)',
      option_label: 'Namibia (+264)',
      value: 264
    },
    {
      selected_label: '(+674)',
      option_label: 'Nauru (+674)',
      value: 674
    },
    {
      selected_label: '(+977)',
      option_label: 'Nepal (+977)',
      value: 977
    },
    {
      selected_label: '(+31)',
      option_label: 'Netherlands (+31)',
      value: 31
    },
    {
      selected_label: '(+599)',
      option_label: 'Netherlands Antilles (+599)',
      value: 599
    },
    {
      selected_label: '(+687)',
      option_label: 'New Caledonia (+687)',
      value: 687
    },
    {
      selected_label: '(+64)',
      option_label: 'New Zealand (+64)',
      value: 64
    },
    {
      selected_label: '(+505)',
      option_label: 'Nicaragua (+505)',
      value: 505
    },
    {
      selected_label: '(+227)',
      option_label: 'Niger (+227)',
      value: 227
    },
    {
      selected_label: '(+234)',
      option_label: 'Nigeria (+234)',
      value: 234
    },
    {
      selected_label: '(+47)',
      option_label: 'Norway (+47)',
      value: 47
    },
    {
      selected_label: '(+968)',
      option_label: 'Oman (+968)',
      value: 968
    },
    {
      selected_label: '(+92)',
      option_label: 'Pakistan (+92)',
      value: 92
    },
    {
      selected_label: '(+970)',
      option_label: 'Palestine (+970)',
      value: 970
    },
    {
      selected_label: '(+507)',
      option_label: 'Panama (+507)',
      value: 507
    },
    {
      selected_label: '(+675)',
      option_label: 'Papua New Guinea (+675)',
      value: 675
    },
    {
      selected_label: '(+595)',
      option_label: 'Paraguay (+595)',
      value: 595
    },
    {
      selected_label: '(+51)',
      option_label: 'Peru (+51)',
      value: 51
    },
    {
      selected_label: '(+63)',
      option_label: 'Philippines (+63)',
      value: 63
    },
    {
      selected_label: '(+48)',
      option_label: 'Poland (+48)',
      value: 48
    },
    {
      selected_label: '(+351)',
      option_label: 'Portugal (+351)',
      value: 351
    },
    {
      selected_label: '(+1787)',
      option_label: 'Puerto Rico (+1787)',
      value: 1787
    },
    {
      selected_label: '(+974)',
      option_label: 'Qatar (+974)',
      value: 974
    },
    {
      selected_label: '(+262)',
      option_label: 'Reunion (+262)',
      value: 262
    },
    {
      selected_label: '(+40)',
      option_label: 'Romania (+40)',
      value: 40
    },
    {
      selected_label: '(+7)',
      option_label: 'Russia (+7)',
      value: 7
    },
    {
      selected_label: '(+250)',
      option_label: 'Rwanda (+250)',
      value: 250
    },
    {
      selected_label: '(+1869)',
      option_label: 'Saint Kitts and Nevis (+1869)',
      value: 1869
    },
    {
      selected_label: '(+1758)',
      option_label: 'Saint Lucia (+1758)',
      value: 1758
    },
    {
      selected_label: '(+685)',
      option_label: 'Samoa (+685)',
      value: 685
    },
    {
      selected_label: '(+378)',
      option_label: 'San Marino (+378)',
      value: 378
    },
    {
      selected_label: '(+239)',
      option_label: 'Sao Tome and Principe (+239)',
      value: 239
    },
    {
      selected_label: '(+966)',
      option_label: 'Saudi Arabia (+966)',
      value: 966
    },
    {
      selected_label: '(+221)',
      option_label: 'Senegal (+221)',
      value: 221
    },
    {
      selected_label: '(+381)',
      option_label: 'Serbia (+381)',
      value: 381
    },
    {
      selected_label: '(+248)',
      option_label: 'Seychelles (+248)',
      value: 248
    },
    {
      selected_label: '(+232)',
      option_label: 'Sierra Leone (+232)',
      value: 232
    },
    {
      selected_label: '(+65)',
      option_label: 'Singapore (+65)',
      value: 65
    },
    {
      selected_label: '(+421)',
      option_label: 'Slovakia (+421)',
      value: 421
    },
    {
      selected_label: '(+386)',
      option_label: 'Slovenia (+386)',
      value: 386
    },
    {
      selected_label: '(+677)',
      option_label: 'Solomon Islands (+677)',
      value: 677
    },
    {
      selected_label: '(+252)',
      option_label: 'Somalia (+252)',
      value: 252
    },
    {
      selected_label: '(+27)',
      option_label: 'South Africa (+27)',
      value: 27
    },
    {
      selected_label: '(+34)',
      option_label: 'Spain (+34)',
      value: 34
    },
    {
      selected_label: '(+94)',
      option_label: 'Sri Lanka (+94)',
      value: 94
    },
    {
      selected_label: '(+1784)',
      option_label: 'St. Vincent and The Gren (+1784)',
      value: 1784
    },
    {
      selected_label: '(+249)',
      option_label: 'Sudan (+249)',
      value: 249
    },
    {
      selected_label: '(+597)',
      option_label: 'Suriname (+597)',
      value: 597
    },
    {
      selected_label: '(+268)',
      option_label: 'Swaziland (+268)',
      value: 268
    },
    {
      selected_label: '(+46)',
      option_label: 'Sweden (+46)',
      value: 46
    },
    {
      selected_label: '(+41)',
      option_label: 'Switzerland (+41)',
      value: 41
    },
    {
      selected_label: '(+963)',
      option_label: 'Syria (+963)',
      value: 963
    },
    {
      selected_label: '(+886)',
      option_label: 'Taiwan (+886)',
      value: 886
    },
    {
      selected_label: '(+992)',
      option_label: 'Tajikistan (+992)',
      value: 992
    },
    {
      selected_label: '(+255)',
      option_label: 'Tanzania (+255)',
      value: 255
    },
    {
      selected_label: '(+66)',
      option_label: 'Thailand (+66)',
      value: 66
    },
    {
      selected_label: '(+670)',
      option_label: 'Timor-Leste (+670)',
      value: 670
    },
    {
      selected_label: '(+228)',
      option_label: 'Togo (+228)',
      value: 228
    },
    {
      selected_label: '(+676)',
      option_label: 'Tonga (+676)',
      value: 676
    },
    {
      selected_label: '(+1868)',
      option_label: 'Trinidad and Tobago (+1868)',
      value: 1868
    },
    {
      selected_label: '(+216)',
      option_label: 'Tunisia (+216)',
      value: 216
    },
    {
      selected_label: '(+90)',
      option_label: 'Turkey (+90)',
      value: 90
    },
    {
      selected_label: '(+993)',
      option_label: 'Turkmenistan (+993)',
      value: 993
    },
    {
      selected_label: '(+1649)',
      option_label: 'Turks and Caicos Islands (+1649)',
      value: 1649
    },
    {
      selected_label: '(+256)',
      option_label: 'Uganda (+256)',
      value: 256
    },
    {
      selected_label: '(+380)',
      option_label: 'Ukraine (+380)',
      value: 380
    },
    {
      selected_label: '(+971)',
      option_label: 'United Arab Emirates (+971)',
      value: 971
    },
    {
      selected_label: '(+44)',
      option_label: 'United Kingdom (+44)',
      value: 44
    },
    {
      selected_label: '(+598)',
      option_label: 'Uruguay (+598)',
      value: 598
    },
    {
      selected_label: '(+998)',
      option_label: 'Uzbekistan (+998)',
      value: 998
    },
    {
      selected_label: '(+678)',
      option_label: 'Vanuatu (+678)',
      value: 678
    },
    {
      selected_label: '(+58)',
      option_label: 'Venezuela (+58)',
      value: 58
    },
    {
      selected_label: '(+84)',
      option_label: 'Vietnam (+84)',
      value: 84
    },
    {
      selected_label: '(+1284)',
      option_label: 'Virgin Islands, British (+1284)',
      value: 1284
    },
    {
      selected_label: '(+967)',
      option_label: 'Yemen (+967)',
      value: 967
    },
    {
      selected_label: '(+260)',
      option_label: 'Zambia (+260)',
      value: 260
    },
    {
      selected_label: '(+263)',
      option_label: 'Zimbabwe (+263)',
      value: 263
    }
  ];
export const countryCodeDoesNotAllowVoice = [86];
